<template>
  <div class="products category-med white px-2 px-md-6">
    <Breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container class="py-0">
      <CategoryTitle
        :category="targetCategory"
        :selectedCategory="selectedCategory"
      />
      <v-chip-group show-arrows v-if="targetCategory.children">
        <v-chip
          v-for="subCategory in targetCategory.children"
          :key="subCategory.categoryId"
          label
          link
          exact-active-class="secondary"
          large
          :to="
            category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }
          "
          class="mr-2 mb-2 grey lighten-1"
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>
    </v-container>
    <v-container>
      <ProductListGrid
        :parentCategoryId="hasCategory ? targetCategory.categoryId : null"
        :categoryId="hasCategory ? categoryId : null"
        :hideCustomFilter="true"
        :key="categoryId || targetCategory.categoryId"
        :promoType="promoType"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>

<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import analyticsServiceGTM from "@/commons/service/analytics/analyticsServiceGTM";
import CategoryService from "~/service/categoryService";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";
import get from "lodash/get";

export default {
  name: "ListiniMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return {
      count: -1,
      categoryId: null,
      targetCategory: null,
      hasCategory: true
    };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.targetCategory.slug
          }
        },
        text: this.targetCategory.name,
        exact: true
      });
      if (this.categoryId && this.targetCategory.children) {
        let subCategory = this.targetCategory.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.targetCategory.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.targetCategory.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  async created() {
    var _this = this;
    if (_this.$route.query.targetCategory) {
      _this.targetCategory = await CategoryService.getCategoryBySlug(
        _this.$route.query.targetCategory
      );
      _this.categoryId = toNumber(_this.targetCategory.categoryId);
    } else {
      if (!_this.targetCategory) {
        _this.targetCategory = this.category;
        this.hasCategory = false;
      }
    }
    if (_this.$route.query.parent_warehouse_promo_facet_id)
      _this.promoType = _this.$route.query.parent_warehouse_promo_facet_id;
  },
  watch: {
    async targetCategory() {
      this.categoryId = this.targetCategory.categoryId;
    }
  },
  metaInfo() {
    const categories = Object.values(
      analyticsServiceGTM.getCategoryPath(this.targetCategory.categoryId)
    );
    const leafCategory = categories[categories.length - 1];
    const parentCategory = categories[categories.length - 2];
    let title = null;
    let description = null;
    // This if checks for a real category-med
    if (categories.length && leafCategory && parentCategory) {
      title = get(
        this.targetCategory,
        "metaData.category_seo.SEO_TITLE",
        this.$t("meta.category.secondLevel.title", [
          leafCategory,
          parentCategory.toLowerCase()
        ])
      );
      description = get(
        this.targetCategory,
        "metaData.category_seo.SEO_DESCRIPTION",
        this.$t("meta.category.secondLevel.description", [leafCategory])
      );
      // Instead, this case refers to a Custom Category which couldn't have parent category, so these are the SEO rules as category-main
    } else {
      title = get(
        this.targetCategory,
        "metaData.category_seo.SEO_TITLE",
        this.$t("meta.category.firstLevel.title", [this.targetCategory.name])
      );
      description = get(
        this.targetCategory,
        "metaData.category_seo.SEO_DESCRIPTION",
        this.$t("meta.category.firstLevel.description", [
          this.targetCategory.name
        ])
      );
    }

    console.log("TITLE", title, "DESC", description);
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.targetCategory.metaData?.category_info?.HEADER_IMAGE ||
            this.targetCategory.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Zona.eu"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
